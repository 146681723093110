import { handleError } from '../helper';
import { API_ENDPOINT } from '../constants';

/*------------------------------------
********VERIFICAR RECAPTCHA***********
-------------------------------------*/
export async function verifyTokenCaptcha(value){

    try{

        let response = await fetch(`${API_ENDPOINT}recaptcha/api/siteverify`, {method:'POST', body:JSON.stringify({data:{token:value}}),  headers:{
            'Content-Type': 'application/json'
          }});
        response = await response.json();
        response = {
            data: response
        };
        if (handleError(response)){
            return {
                data:response.data.data,
                error:false,
                message:response.data.messages.msg
            }
        }else{
            return {
                data:null,
                error:true,
                message:response.data.messages.msg
            }
        }
    }catch(error){
        return {
            data:null,
            error:true,
            message:"Hubo un problema al verificar su identidad"
        }
    }

}
/**
 * obtener los tipos de personas
 * @returns Array
 */
export function setTypesPeople(){
    var typesPerson = [
      {
        id: 0,
        value: 'PERSONA NATURAL',
        index:1
      },
      {
        id: 1,
        value:'PERSONA JURÍDICA',
        index:1
      }
    ]
    
    return typesPerson
  }

/*-----------------------------
******OBTENER TIPOS DE ID******
------------------------------*/
export async function getIdTypes(){
    try{
        let response = await fetch(`${API_ENDPOINT}ebportal/idtypes`, {method:'GET'});
        response = await response.json();
        response = {
            data: response
        };
        if (handleError(response)){
            return {
                data: response.data.data,
                error: false,
                message: response.data.messages.msg
            }
        }else{
            return {
                data:null,
                error: true,
                message:response.data.messages.msg
            }
        }
    }catch( error){
        return {
            data:null,
            error:true,
            message:"Hubo un error al obtener los tipos de documento"
        }
    }
}

/*-----------------------------
******GUARDAR DATOS PERSONA****
------------------------------*/
export async function postPerson(data){
    const fileRut = document.getElementById("user_rut")
    try{
        let response = await fetch(`${API_ENDPOINT}/ebportal/save`, {method:'POST',body:JSON.stringify({data:data}),  headers:{
            'Content-Type': 'application/json'
          }});
        response = await response.json();
        response = {
            data: response
        };
        if (handleError(response)){
            return {
                data:response.data.data,
                error:false,
                message:response.data.messages.msg
            }
            
        }else{
            return {
                data:null,
                error:true,
                message:response.data.messages.msg
            }
        }
    }catch(error){
        return {
            data:null,
            error:true,
            message:"Hubo un problema al enviar la información de la persona"
        }
    }

}

/*-----------------------------
******RETORNA DIGITO DE VERIFICACION*****
David A. Ciro 26/03/2021
------------------------------*/
export async function getCd(doc_number){
    try{
        let response = await fetch(`${API_ENDPOINT}/ebportal/getCd`, {method:'POST',body:JSON.stringify({data:doc_number}), headers:{
            'Content-Type': 'application/json'
          }});
        response = await response.json();
        response = {
            data: response
        };
        if (handleError(response)){
            return {
                data:response.data.data,
                error:false,
                message:response.data.messages.msg
            }
            
        }else{
            return {
                data:null,
                error:true,
                message:response.data.messages.msg
            }
        }
    }catch(error){
        return {
            data:null,
            error:true,
            message:"Hubo un problema al consultar el DV"
        }
    }
}
/*-----------------------------
******GUARDAR DOCUMENTO RUT PERSONA*****
David A. Ciro 26/03/2021


------------------------------*/
export async function saveRutfile(id_user){
    var rut_file = new FormData();
    const fileRut = document.getElementById("user_rut")
    rut_file.append('rut_file',fileRut.files[0]);
    rut_file.append('id_user', id_user);
    try {
        var res = await fetch(`${API_ENDPOINT}/ebportal/saveRut`, {method:'POST',body:rut_file});

        var data_response = await res.json();
        if(!data_response.messages.error){
            return {
                data:data_response,
                error:false,
                message:"Se registró el usuario y se ha guardado el documento rut"
            }
        }else{
            return {
                data:null,
                error:true,
                message:"Se ha registrado el usuario pero no se ha podido guardar el archivo"
            }
        }
    } catch (e) {
        return {
            data:null,
            error:true,
            message:"Se ha registrado el usuario pero no se ha podido guardar el archivo error: " + e
        }
    }
}


/*-----------------------------
******EDITAR DATOS PERSONA*****
------------------------------*/
export async function editPerson(data){
    try{
        let response = await fetch(`${API_ENDPOINT}/ebportal/editperson`, {method:'POST',body:JSON.stringify({data:data}),  headers:{
            'Content-Type': 'application/json'
          }});
        response = await response.json();
        response = {
            data: response
        };
        if (handleError(response)){
            return {
                data:response.data.data,
                error:false,
                message:response.data.messages.msg
            }
        }else{
            return {
                data:null,
                error:true,
                message:response.data.messages.msg
            }
        }
    }catch(error){
        return {
            data:null,
            error:true,
            message:"Hubo un problema al enviar la información de la persona"
        }
    }

}


/*-----------------------------
*VERIFICAR PERSONA Y MANDAR KEY*
------------------------------*/
export async function verificationPerson(data){

    try{

        let response = await fetch(`${API_ENDPOINT}/ebportal/editionkey`, {method:'POST',body:JSON.stringify({data:data}),  headers:{
            'Content-Type': 'application/json'
          }});
        response = await response.json();
        response = {
            data: response
        };
        if (handleError(response)){
            return {
                data:response.data.data,
                error:false,
                message:response.data.messages.msg
            }
        }else{
            return {
                data:null,
                error:true,
                message:response.data.messages.msg
            }
        }
    }catch(error){
        return {
            data:null,
            error:true,
            message:"Hubo un problema al enviar la información de la persona"
        }
    }

}

/*------------------------------------
*VERIFICAR KEY Y OBTENER DATOS PERSONA*
-------------------------------------*/
export async function verificationKey(data){

    try{

        let response = await fetch(`${API_ENDPOINT}/ebportal/peopledetail`, {method:'POST',body:JSON.stringify({data:data}),  headers:{
            'Content-Type': 'application/json'
          }});
        response = await response.json();
        response = {
            data: response
        };
        if (handleError(response)){
            return {
                data:response.data.data,
                error:false,
                message:response.data.messages.msg
            }
        }else{
            return {
                data:null,
                error:true,
                message:response.data.messages.msg
            }
        }
    }catch(error){
        return {
            data:null,
            error:true,
            message:"Hubo un problema al verificar la llave"
        }
    }

}

/*------------------------------------
*********OBTENER DEPARTAMENTOS********
-------------------------------------*/
export async function getDepartments(data){
    try{
        let response = await fetch(`${API_ENDPOINT}ebportal/departments`, {method:'GET'});
        response = await response.json();
        response = {
            data: response
        };
        if (handleError(response)){
            return {
                data:response.data.data,
                error:false,
                message:response.data.messages.msg
            }
        }else{
            return {
                data:null,
                error:true,
                message:response.data.messages.msg
            }
        }
    }catch(error){
        return {
            data:null,
            error:true,
            message:"Hubo un problema al obtener el listado de departamentos"
        }
    }
}

/*------------------------------------
**OBTENER CIUDADES DE UN DEPARTAMENTO**
-------------------------------------*/
export async function getCitiesDepartment(data){
    try{
        let response = await fetch(`${API_ENDPOINT}ebportal/citiesbydep/${data.id}`, {method:'GET'});
        response = await response.json();
        response = {
            data: response
        };
        if (handleError(response)){
            return {
                data:response.data.data,
                error:false,
                message:response.data.messages.msg
            }
        }else{
            return {
                data:null,
                error:true,
                message:response.data.messages.msg
            }
        }
    }catch(error){
        return {
            data:null,
            error:true,
            message:"Hubo un problema al obtener el listado de ciudades"
        }
    }
}

/*------------------------------------
******OBTENER TODAS LAS CIUDADES******
-------------------------------------*/
export async function getCities(data){
    try{
        let response = await fetch(`${API_ENDPOINT}ebportal/cities`, {method:'GET'});
        response = await response.json();
        response = {
            data: response
        };
        if (handleError(response)){
            return {
                data:response.data.data,
                error:false,
                message:response.data.messages.msg
            }
        }else{
            return {
                data:null,
                error:true,
                message:response.data.messages.msg
            }
        }
    }catch(error){
        return {
            data:null,
            error:true,
            message:"Hubo un problema al obtener el listado de ciudades"
        }
    }
}

/*------------------------------------
***OBTENER UNA VENTA PARA FACTURAR***
-------------------------------------*/
export async function getSaleDetail(data){

    try{

        let response = await fetch(`${API_ENDPOINT}/ebportal/saledetail`, {method:'POST',body:JSON.stringify({data:data}),  headers:{
            'Content-Type': 'application/json'
          }});
        response = await response.json();
        response = {
            data: response
        };
        if (handleError(response)){
            return {
                data:response.data.data,
                error:false,
                message:response.data.messages.msg
            }
        }else{
            return {
                data:null,
                error:true,
                message:response.data.messages.msg
            }
        }
    }catch(error){
        return {
            data:null,
            error:true,
            message:"Hubo un problema al enviar la información de la venta"
        }
    }
}

/*------------------------------------
***********FACTURAR UNA VENTA*********
-------------------------------------*/
export async function linkSale(data){

    try{

        let response = await fetch(`${API_ENDPOINT}/ebportal/linksale`, {method:'POST',body:JSON.stringify({data:data}),  headers:{
            'Content-Type': 'application/json'
          }});
        response = await response.json();
        response = {
            data: response
        };
        if (handleError(response)){
            return {
                data:response.data.data,
                error:false,
                message:response.data.messages.msg
            }
        }else{
            return {
                data:null,
                error:true,
                message:response.data.messages.msg
            }
        }
    }catch(error){
        return {
            data:null,
            error:true,
            message:"Hubo un problema al enviar la información de la venta"
        }
    }

}

export async function queryPerson(document, documentTypeId){
    try{
        let response = await fetch(`${API_ENDPOINT}ebportal/query/${document}/${documentTypeId}`, {method:'GET'});
        response = await response.json();
        response = {
            data: response
        };
        if (handleError(response)){
            return {
                data:response.data.data,
                error:false,
                message:response.data.messages.msg
            }
        }else{
            return {
                data:null,
                error:true,
                message:response.data.messages.msg
            }
        }
    }catch(error){
        return {
            data:null,
            error:true,
            message:"Hubo un problema al enviar la información de la venta"
        }
    } 
}

